<template>
  <div v-if="showPreload" class="row items-center justify-center" style="width: 200px;position: absolute; top: 0; bottom: 0; left: 0; right: 0; margin: auto auto">
    <lottie-animation
        ref="anim"
        :animationData="require('../assets/preload-animation.json')"
        :loop="true"
        :autoPlay="true"
        :speed="1"
        style="width: 200px; height: 200px"
    />
  </div>
  <q-layout v-else view="hHh Lpr Lff">
    <q-header elevated class="page-header page-header_home">
      <q-toolbar>
        <q-btn flat round dense icon="menu" @click="showSidebar"/>
        <q-toolbar-title>
          {{toolbarTitle || 'Logexpert Видео Сервис'}}
        </q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-drawer
        v-model="drawer"
        class="border-for-left-drawer"
    >
      <div class="q-pa-md">
        <div class="q-pa-md q-gutter-md absolute" style="top: 10px; right: 10px">
          <q-badge outline class="theme-color">
           Версия сайта: {{versionSite}}
          </q-badge>
        </div>
        <q-avatar size="38px" class="q-mb-sm">
          <img src="../assets/icons8-пользователь-мужчина-в-кружке-50.png"/>
        </q-avatar>
        <div class="row justify-between col">
          <div  v-if="user">
            <div class="text-bold">
              {{user.first_name}} {{user.last_name}}
            </div>
            <div class="text-grey">
              {{user.email}}
            </div>
          </div>

          <q-btn flat round dense icon="power_settings_new" @click="confirm = true" color="red" class="col-2">
            <q-tooltip>Выйти</q-tooltip>
          </q-btn>

          <q-dialog v-model="confirm">
            <q-card>
              <q-card-section class="row items-center">
                <q-avatar icon="logout" color="primary" text-color="white" />
                <span class="q-ml-sm">Вы действительно хотите выйти?</span>
              </q-card-section>

              <q-card-actions align="right">
                <q-btn flat label="Отмена" color="primary" v-close-popup />
                <q-btn flat label="Ок" color="primary" @click="logout" />
              </q-card-actions>
            </q-card>
          </q-dialog>

        </div>
        <br/>
        <q-separator/>
      </div>

      <q-scroll-area style="height: 60%">
        <q-list>
          <q-item clickable to="/" exact>
            <q-item-section avatar>
              <q-icon name="dashboard_customize" />
            </q-item-section>
            <q-item-section>
              Дашборд
            </q-item-section>
          </q-item>

          <q-item clickable to="/tasks">
            <q-item-section avatar>
              <q-icon name="task"/>
            </q-item-section>
            <q-item-section>
              Задания
            </q-item-section>
          </q-item>

          <q-item clickable to="/units">
            <q-item-section avatar>
              <q-icon name="local_shipping"/>
            </q-item-section>
            <q-item-section>
              Транспорт
            </q-item-section>
          </q-item>

          <q-item v-if="devicePermissions && devicePermissions.length" clickable to="/tracks">
            <q-item-section avatar>
              <q-icon name="map"/>
            </q-item-section>
            <q-item-section>
              Треки
            </q-item-section>
          </q-item>

<!--          <q-item clickable to="/map">-->
<!--            <q-item-section avatar>-->
<!--              <q-icon name="map"/>-->
<!--            </q-item-section>-->
<!--            <q-item-section>-->
<!--              Карта-->
<!--            </q-item-section>-->
<!--          </q-item>-->

          <q-item v-if="devicePermissions && devicePermissions.length" clickable to="/devices">
            <q-item-section avatar>
              <q-icon name="settings_remote"/>
            </q-item-section>
            <q-item-section>
              Устройства
            </q-item-section>
          </q-item>

        </q-list>
      </q-scroll-area>
    </q-drawer>

    <q-page-container>
      <router-view/>
    </q-page-container>
  </q-layout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {VERSION} from "../constants";
import LottieAnimation from 'lottie-web-vue';

const versionKey = 'version';

export default {
  name: 'Home',
  components: {LottieAnimation},
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'lvs',
    // all titles will be injected into this template
    titleTemplate: '%s | lvs'
  },
  data() {
    return {
      unit: null,
      drawer: true,
      user: null,
      versionSite: VERSION,
      confirm: false,
      havePermission: true,
      showPreload: true
    }
  },

  async mounted() {
    const permissions = await this.server.permissions.readMany({
      limit: -1
    })

    await this.setPermissions(permissions.data)

    // console.debug('home mounted device is have device permissions', this.devicePermissions?.length)

    await this.fetchUser()

    if(localStorage[versionKey] && localStorage[versionKey] !== this.versionSite) {
      this.$q.notify({
        type: 'warning',
        message: `Вышла новая версия сайта: ${this.versionSite}! Пожалуйста, перейдите на новую версию.`,
        actions: [
          {
            label: 'Перейти на новую версию сайта', color: 'black', handler: () => {
              location.reload()
            }
          }
        ]
      })

    }
    if(localStorage[versionKey] !== this.versionSite) {
      localStorage[versionKey] = this.versionSite;
    }
    this.showPreload = false
  },

  methods: {
    ...mapActions({
      'setPermissions': 'server/setPermissions'
    }),

    async logout() {
      try {
        await this.server.auth.logout()
        await this.$router.push({ name: 'Login' })
      }
      catch (e) {
        this.$q.notify({
          message: `Ошибка выхода: ${e.message}`,
          color: 'red'
        })
      }
    },

    async fetchUser() {
      try {
        this.user = await this.server.users.me.read()
      }
      catch (e) {
        this.$q.notify({
          message: `Ошибка получения пользователя: ${e.message}`,
          color: 'red'
        })
      }
    },

    showSidebar() {
      this.drawer = !this.drawer;
      const rowFixed = document.querySelector('.row-fixed');
      if (rowFixed !== null) {
        rowFixed.classList.toggle('sidebar--active');
      }
    },
  },

  computed: {
    ...mapGetters({
      'server': 'server/instance',
      'toolbarTitle': 'layout/toolbarTitle',
      'devicePermissions': 'server/devicePermissions'
    }),

    themeСolor() {
      return `color: ${$primary}`;
    }
  }
}
</script>

<style lang="sass">
@import "src/styles/quasar.variables.sass"

.border-for-left-drawer > .q-drawer
  border-right: 1px solid $light-primary

.border-for-right-drawer > .q-drawer
  border-left: 1px solid $light-primary

.border-for-header
  border-bottom: 1px solid $light-primary

.border-for-footer
  border-top: 1px solid $light-primary

.q-page
  min-height: 1039px
  width: 100%
  height: 100%
  background-image: $background_image
  background-color: whitesmoke

.q-item.q-router-link--active, .q-item--active
  background: $primary
  color: white

.sidebar--active
  left: 0px !important

.theme-color
  color: $primary

.custom-q-tab-panel * .custom-q-layout
  min-height: calc(100vh - 69px) !important

.custom-q-tab-panel > .custom-q-page
  min-height: calc(100vh - 69px) !important

.custom-dashboard-title
  font-weight: 700
  font-style: italic
  font-size: 16px

.custom-dashboard-title_small
  font-weight: 400
  font-size: 16px
  color: gray

</style>